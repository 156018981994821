<template>
  <div>
    <!-- select 2 demo -->

    <div>
      <!-- Table Top -->
      <div>
        <b-sidebar
          id="sidebar-right"
          ref="mySidebar"
          bg-variant="white"
          v-model="visibility"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">
              {{ sidebarTitle }}
            </h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hideSideBar()"
            />
          </div>
          <b-form class="p-2" @submit.prevent>
            <b-row class="mt-1">
              <b-col md="12">
                <b-form-group
                  label="Subject"
                  invalid-feedback="Subject is required."
                  ref="subject"
                >
                  <!-- <b-form-input
                    id="mc-first-name"
                    placeholder="Enter subject name"
                    v-validate="'required'"
                    name="classField"
                    ref="subject"
                    v-model="myObj.parentID"
                  /> -->
                  <v-select
                    :disabled="!adding"
                    ref="subject"
                    v-model="myObj.parentID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="parentOptions"
                    :reduce="(val) => val.id"
                    label="subject"
                    :clearable="false"
                    @input="setChildOpt()"
                    placeholder="Select subject"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Group Subjects"
                  invalid-feedback="Group is required."
                  ref="child"
                >
                  <!-- <b-form-textarea
                    ref="child"
                    placeholder="Enter subjects seperated by comma"
                    v-model="childrenSubs"
                    rows="4"
                  ></b-form-textarea> -->
                  <v-select
                    ref="child"
                    multiple
                    :closeOnSelect="false"
                    v-model="childrenSubs"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="childOptions"
                    :reduce="(val) => val.id"
                    label="subject"
                    :clearable="false"
                    placeholder="Select group subjects"
                  />
                </b-form-group>
              </b-col>

              <!-- <b-col md="12" class="mb-1 d-flex">
                <b-button
                  :variant="myObj.isOptional ? 'outline-primary' : 'primary'"
                  class="mr-1"
                  style="width: 50%"
                  @click="myObj.isOptional = false"
                >
                  <span>Mandatory</span>
                </b-button>
                <b-button
                  :variant="myObj.isOptional ? 'primary' : 'outline-primary'"
                  @click="myObj.isOptional = true"
                  style="width: 50%"
                >
                  <span>Optional</span>
                </b-button>
              </b-col> -->

              <b-col md="12" class="mt-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="success"
                  @click="Add()"
                  :disabled="request"
                  block
                >
                  <b-spinner v-if="request" small type="grow" />
                  <span v-if="request == false"> {{ sidebarButton }} </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <b-card>
          <b-row class="mt-1">
            <!-- v-if="rights.add" -->
            <b-col xl="2" lg="3" md="4" sm="12" cols="12">
              <b-button
                @click="AddOpen()"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle">Add Groups</span>
              </b-button>
            </b-col>

            <b-col
              class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
              xl="10"
              lg="9"
              md="8"
              sm="12"
              cols="12"
            >
              <b-form-group class="">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>

                  <b-form-input v-model="searchQuery" placeholder="Search...">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-table
            class="mt-1"
            ref="selectableTable"
            :items="filters"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :busy="dataLoading"
            show-empty
            responsive
            hover
          >
            <!-- :selectable="rights.edit"
            select-mode="single"
            @row-selected="Edit($event[0].id)" -->
            <template #empty="scope">
              <h3 class="mt-1 mb-1" style="text-align: center">
                No records found
              </h3>
            </template>
            <template #table-busy>
              <div
                class="d-flex justify-content-center mb-3"
                style="margin-top: 50px"
              >
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  type="grow"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
            </template>
            <template #cell(parentLable)="data">
              <b-badge variant="light-primary">
                {{ data.item.parentLable }}
              </b-badge>
            </template>

            <template #cell(subSubjectLable)="data">
              <!-- <b-badge variant="light-primary">
                {{ data.item.subSubjectLable }}
              </b-badge> -->
              <b-badge
                variant="light-primary"
                v-for="sub in data.item.subSubjectLable.split(',')"
                :key="sub"
                style="margin: 2px"
              >
                <span>{{ sub }}</span>
              </b-badge>
            </template>

            <template #head(actions)="data">
              <div class="text-center">
                <span>{{ data.label }}</span>
              </div>
            </template>

            <template #cell(actions)="data">
              <div class="text-center">
                <!-- v-if="rights.edit" -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  v-b-tooltip.hover.left
                  title="Edit"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Edit(data.item)"
                >
                  <feather-icon icon="EditIcon" class="" />
                </b-button>

                <!-- v-if="rights.delete" -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  v-b-tooltip.hover.right
                  title="Delete"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Delete(data.item.parentID)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </template>
          </b-table>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="filters.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    BFormTextarea,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    // let right = {};
    // this.$store.state.menu.forEach((el) => {
    //   el.children.forEach((ch) => {
    //     right[ch.route] = {
    //       view: ch.view,
    //       add: ch.add,
    //       edit: ch.edit,
    //       delete: ch.delete,
    //     };
    //   });
    // });
    // // console.log(right);
    // this.$store.commit("setRights", right);
    // if (!this.$store.state.rights[this.$route.name]) {
    //   // console.log(this.$store.state.rights[this.$route.name]);
    //   this.$router.replace({
    //     name: "misc-not-authorized",
    //   });
    // } else {
    //   this.rights = this.$store.state.rights[this.$route.name];
    //   this.LoadData();
    // }
    this.LoadData();
    this.loadGrid();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    filters: function () {
      return this.gridData.filter((pro) => {
        return pro.parentLable
          .toLowerCase()
          .match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      rights: {},
      gridData: [],
      request: false,
      visibility1: false,
      sidebarTitle: "Add Subject",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,
      fields: [
        { label: "Subject", key: "parentLable" },
        { label: "groups", key: "subSubjectLable" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      childOptions: [],
      parentOptions: [],
      rangeDate: null,
      adding: false,
      myObj: {
        id: 0,
        parentID: 0,
        subSubjects: [],
        parentLable: "",
        subSubjectLable: "",
      },
      childrenSubs: [],
      selectedSubs: [],
    };
  },

  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    setChildOpt() {
      this.childOptions = this.parentOptions.filter(
        (el) => el.id != this.myObj.parentID
      );
      this.childrenSubs = this.childrenSubs.filter(
        (el) => el != this.myObj.parentID
      );
      // this.childOptions = this.items.filter(
      //   (el) => el.id != this.myObj.parentID
      // );
      // this.childrenSubs = this.childrenSubs.filter(
      //   (el) => el != this.myObj.parentID
      // );
      // console.log(this.childrenSubs, this.myObj.parentID);
    },

    async Edit(item) {
      // var obj = {
      //   url:
      //     this.$store.state.domain +
      //     "Subjects/GetSelected?id=" +
      //     id +
      //     "&db=" +
      //     this.$store.state.userData.db,
      //   token: this.$store.state.userData.token,
      // };
      // this.myObj = await this.get(obj);
      // console.log("editObj:", this.myObj);

      this.myObj = { ...item };
      this.childrenSubs = item.subSubjects;

      // ====== previous =======

      // this.selectedSubs = [];
      // this.gridData.forEach((el) => {
      //   if (el.parentID != this.myObj.parentID) {
      //     this.selectedSubs.push(el.parentID);
      //     this.selectedSubs = [...this.selectedSubs, ...el.subSubjects];
      //   }
      // });
      // // console.log(this.selectedSubs);

      // // this.childOptions = this.items;
      // this.childOptions = this.items.filter(
      //   (el) =>
      //     !this.selectedSubs.includes(el.id) && el.id != this.myObj.parentID
      // );

      // this.parentOptions = this.items.filter(
      //   (el) => el.id == this.myObj.parentID
      // );

      // ====== previous =======

      // ====== new =======
      this.selectedSubs = [];
      this.gridData.forEach((el) => {
        if (el.parentID != this.myObj.parentID) {
          this.selectedSubs.push(el.parentID);
        }
      });
      // console.log(this.selectedSubs);

      this.childOptions = this.items.filter(
        (el) =>
          !this.selectedSubs.includes(el.id) && el.id != this.myObj.parentID
      );

      this.parentOptions = this.items.filter(
        (el) => el.id == this.myObj.parentID
      );
      // ====== new =======

      this.visibility = true;
      this.adding = false;
      this.sidebarTitle = "Edit Subject";
      this.sidebarButton = "Update";
      var elem = this.$refs["subject"];
      elem.state = undefined;
      var elem = this.$refs["child"];
      elem.state = undefined;
    },

    AddOpen() {
      this.myObj = {
        id: 0,
        parentID: 0,
        subSubjects: [],
        parentLable: "",
        subSubjectLable: "",
      };

      // ====== previous =======
      // this.selectedSubs = [];
      // this.gridData.forEach((el) => {
      //   this.selectedSubs.push(el.parentID);
      //   this.selectedSubs = [...this.selectedSubs, ...el.subSubjects];
      // });
      // // console.log(this.selectedSubs);

      // // this.childOptions = this.items;
      // this.childOptions = this.items.filter(
      //   (el) => !this.selectedSubs.includes(el.id)
      // );

      // this.parentOptions = [...this.childOptions];

      // ====== previous =======

      // ====== new =======
      this.selectedSubs = [];
      this.gridData.forEach((el) => {
        this.selectedSubs.push(el.parentID);
      });
      // console.log(this.selectedSubs);

      this.childOptions = this.items.filter(
        (el) => !this.selectedSubs.includes(el.id)
      );

      this.parentOptions = [...this.childOptions];

      // ====== new =======

      this.childrenSubs = [];
      this.adding = true;
      this.visibility = true;
      this.sidebarTitle = "Add Subject";
      this.sidebarButton = "Save";
      var elem = this.$refs["subject"];
      elem.state = undefined;
      var elem = this.$refs["child"];
      elem.state = undefined;
    },

    CheckName() {
      var elem = this.$refs["subject"];
      if (this.myObj.parentID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkChild() {
      var elem = this.$refs["child"];
      if (this.childrenSubs.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },

    async LoadData() {
      var obj = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      this.childOptions = this.items;
      // console.log("subject", this.items);
    },
    async loadGrid() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "SubjectGroups/LoadData?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.gridData = await this.get(obj);

      // console.log("grid", this.gridData);
      this.dataLoading = false;
    },

    async Add() {
      // this.childrenSubs = this.childrenSubs.replace(/\n/g, " ");
      // this.myObj.children = this.childrenSubs.split(",");
      // console.log(this.childrenSubs, this.myObj.children);
      this.CheckName();
      this.checkChild();
      if (this.CheckName() == true && this.checkChild() == true) {
        this.myObj.subSubjects = this.childrenSubs;
        // console.log(this.myObj);
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "SubjectGroups/Save?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.myObj,
          message: "Subject group saved successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        this.visibility = false;
        if (status) this.loadGrid();
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.post({
          url:
            this.$store.state.domain +
            "SubjectGroups/Remove?parentID=" +
            id +
            "&db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: null,
          message: "Subject group removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadGrid();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
